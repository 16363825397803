import { formatNumberCompact } from "../../../utils/formatNumber";
import { getHorizontalBarOptions } from "../_generic/chartOptions";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import ReactEcharts from "echarts-for-react";

const EcommerceOverview = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        brand = "ERROR",
        noOfProducts = "ERROR",
        productTypes = [],
        etailers = [],
    } = slideContent;

    return (
        <Layout showFull={showFull} className={"text-black"}>
            <div className="min-h-[690px] w-full flex flex-col">
                <div className="grid grid-cols-2">
                    <div
                        className="p-10 h-fit"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div>
                            <h1 className=" text-4xl font-medium ">
                                {"E-Commerce Overview"}
                            </h1>
                            <p className="mt-4 text-sm font-medium">
                                BRAND:{" "}
                                <span className="text-eyvablue">{brand}</span>{" "}
                            </p>
                        </div>
                    </div>
                    <div
                        className="h-full"
                        style={{ background: "white" }}
                    ></div>
                </div>

                <div className="grid grid-cols-2 grow">
                    <div
                        className="px-10 space-y-10"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div>
                            <p className=" text-[#DC715A] font-semibold text-xl">
                                {formatNumberCompact(noOfProducts)}
                            </p>
                            <p className="text-xs text-gray-700">
                                Total Number of Products on E-Tailers
                            </p>
                        </div>

                        <div>
                            <p className="font-medium">TOP PRODUCT TYPES</p>
                            <ReactEcharts
                                option={getHorizontalBarOptions({
                                    data: productTypes,
                                    color: "#DC715A",
                                })}
                                opts={{ renderer: "svg" }}
                                style={{ width: "560px", height: "340px" }}
                            />
                        </div>
                    </div>

                    <div className="px-10 mt-[85px]">
                        <div className="font-medium">
                            E-TAILERS WHERE THIS BRAND HAS LISTED PRODUCTS
                        </div>
                        <ReactEcharts
                            option={getHorizontalBarOptions({
                                data: etailers,
                                color: "#A3BCBA",
                            })}
                            opts={{ renderer: "svg" }}
                            style={{ width: "560px", height: "340px" }}
                        />
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default EcommerceOverview;
