import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const TableOfContents = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let { brand = "ERROR", textHTML = "ERROR", contents = [] } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-[#F0ECE6] py-10 px-12">
                <h1 className=" text-4xl font-medium">
                    {"Data Scope + Table of Contents"}
                </h1>
                <p className="mt-4 text-sm font-medium">
                    BRAND: <span className="text-eyvablue">{brand}</span>{" "}
                </p>

                <div className="mt-5 text-base leading-8 text-black">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: textHTML,
                        }}
                    />
                </div>
                <div className="mt-8">
                    <p className="text-2xl font-medium">Table Of Contents</p>
                    <div className="columns-2 gap-14 mt-3">
                        {contents.map((item, id) => (
                            <div
                                key={id}
                                className="py-4 text-sm border-b border-black flex justify-between items-center"
                            >
                                <p>{item}</p>
                                <p>{(id + 1).toString().padStart(2, "0")}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TableOfContents;
