import React from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { Rating } from "../_generic/Rating";
import { padArray } from "../../../utils/padArray";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatNumberCompact } from "../../../utils/formatNumber";

export const MostReviewedProducts = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        lastDataUpdate = "ERROR",
        brand = "ERROR",
        h1 = "ERROR",

        products = [],
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-[#F0ECE6] py-10 px-12">
                <h1 className="text-black text-4xl font-medium">
                    ⭐ Most Reviewed Products
                </h1>
                <p className="mt-4 text-sm font-medium">
                    BRAND: <span className="text-eyvablue">{brand}</span>{" "}
                </p>
                <div className="grid grid-cols-4 gap-4 text-black mt-10">
                    {padArray(products.slice(0, 4), 4, null).map(
                        (product, index) =>
                            product === null ? (
                                <img
                                    key={index}
                                    alt="empty-card"
                                    src="/empty-state-card.png"
                                    className="w-full h-full object-cover rounded-md border border-[#DDC7A8]"
                                />
                            ) : (
                                <div
                                    key={index}
                                    className="border bg-white relative p-6 rounded-md border-gray-300 space-y-10 text-center mx-auto truncate w-full flex flex-col justify-between"
                                >
                                    <div className="space-y-5">
                                        <img
                                            src={product.imgURL}
                                            className="w-48 h-48 object-contain mx-auto"
                                        />
                                        <div className="">
                                            <Rating
                                                rating={product.rating}
                                                color="#000000"
                                            />
                                            <div className="text-center text-sm text-gray-500">
                                                {formatNumberCompact(
                                                    product.reviews,
                                                )}{" "}
                                                Reviews
                                            </div>
                                        </div>

                                        <div className="text-left space-y-1">
                                            <p className="font-medium whitespace-break-spaces line-clamp-1 text-base">
                                                {product.brand}
                                            </p>
                                            <p className="whitespace-break-spaces text-gray-500 line-clamp-1 text-sm">
                                                {product.title}
                                            </p>
                                            <p className="text-sm">
                                                {formatCurrency(
                                                    product.minPrice,
                                                    product.currency,
                                                )}{" "}
                                                -{" "}
                                                {formatCurrency(
                                                    product.maxPrice,
                                                    product.currency,
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ),
                    )}
                </div>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default MostReviewedProducts;
