import { getHorizontalBarOptions } from "../_generic/chartOptions";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import ReactEcharts from "echarts-for-react";
import { Rating } from "../_generic/Rating";
import { formatNumberCompact } from "../../../utils/formatNumber";
import { useMemo } from "react";

const ReviewMentions = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        brand = "ERROR",
        rating = "ERROR",
        reviews = "ERROR",
        positiveMentions = [],
        negativeMentions = [],
        conclusion = "ERROR",
    } = slideContent;

    const maxLabel = useMemo(
        () =>
            Math.max(
                ...positiveMentions.map((item) => item.attr.length),
                ...negativeMentions.map((item) => item.attr.length),
            ),
        [positiveMentions, negativeMentions],
    );

    const maxLabelWidth = useMemo(
        () => `${(maxLabel + 30) * 0.5}%`,
        [maxLabel],
    );

    return (
        <Layout showFull={showFull} className={"text-black"}>
            <div className="min-h-[690px] w-full flex flex-col">
                <div className="grid grid-cols-2">
                    <div
                        className="px-10 py-8 h-fit space-y-5"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div>
                            <h1 className=" text-4xl font-medium ">
                                {"⭐ Review Mentions"}
                            </h1>
                            <p className="mt-4 text-sm font-medium">
                                BRAND:{" "}
                                <span className="text-eyvablue">{brand}</span>{" "}
                            </p>
                        </div>
                        <div className="">
                            <p className="font-medium text-base">
                                <span className="text-[#DC715A]">{rating}</span>{" "}
                                <Rating rating={rating} color="#000000" />
                            </p>
                            <p className="text-xs text-gray-700">
                                Average Rating from{" "}
                                {formatNumberCompact(reviews)} reviews
                            </p>
                        </div>
                    </div>
                    <div
                        className="h-full"
                        style={{ background: "#DDD4C680" }}
                    ></div>
                </div>

                <div className="grid grid-cols-2 grow">
                    <div
                        className="px-10 space-y-10"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div className="">
                            <div>
                                <p className="text-sm font-medium">
                                    POSITIVE MENTIONS
                                </p>

                                <ReactEcharts
                                    option={getHorizontalBarOptions({
                                        data: positiveMentions,
                                        color: "#A3BCBA",
                                        leftPadding: maxLabelWidth,
                                        containLabel: false,
                                    })}
                                    opts={{ renderer: "svg" }}
                                    style={{ width: "560px", height: "240px" }}
                                />
                            </div>
                            <div>
                                <p className="text-sm font-medium">
                                    NEGATIVE MENTIONS
                                </p>

                                <ReactEcharts
                                    option={getHorizontalBarOptions({
                                        data: negativeMentions,
                                        color: "#CD0B52",
                                        leftPadding: maxLabelWidth,
                                        containLabel: false,
                                    })}
                                    opts={{ renderer: "svg" }}
                                    style={{ width: "560px", height: "240px" }}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="px-10 h-full"
                        style={{ background: "#DDD4C680" }}
                    >
                        <div className="font-semibold -mt-16">CONCLUSIONS</div>
                        <div
                            className="mt-7 -space-y-7 font-normal font-sm whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{ __html: conclusion }}
                        ></div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default ReviewMentions;
