import React from "react";

import Footer from "../_generic/Footer";
import { CoverSlideLayout } from "../_generic/CoverSlideLayout";

const Cover = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
}) => {
    if (!slideContent) slideContent = {};

    const {
        h1 = "ERROR",
        imageURL = "https://s3-alpha-sig.figma.com/img/370d/876d/ff5366edd96681bc6d7feec349b34dda?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SQ8CDUqQjKbGj05NBD3nXE7bRYsgTr7hcKPpoqDY95vNUsg33AJ-Eqc0MZ3Q3wdTTIsQVJK-DxdSoAz9WQb10civA~QArtOsrTDXQVUP2P0xkt~d3R2pc9OkJDUdCnG8RuUAHv39Y-aQFBRYk~D83Gbhb3lJKT20Rbk7DwSpbtiLBdATS~JwQhwHakLpB4HPY6GlY6y16QOFNg0awCztxl-aFJgZv7BuatrRjOt8bPUb9H5xhZZe3Ce9aaYVzQo5IQPBbgG0GjrzlpC61INzhXdaHnrs9Mtmxk9zl9GH9fvaBZJ9vfQvHZg69gIkO6Y9s3qZkOwT4ihb0S5assxwIg__",
        topic = "ERROR",
    } = slideContent;

    return (
        <CoverSlideLayout
            imageURL={imageURL}
            footer={
                <Footer
                    slideName={slideName}
                    reportName={reportName}
                    reportVersion={reportVersion}
                    reportCreatedAt={reportCreatedAt}
                    reportLang={reportLang}
                    slideCounter={slideCounter}
                    withBackground={false}
                    dark={false}
                />
            }
            dark={false}
            overlay={"bg-black/30"}
        >
            <div className="relative text-[68px] font-semibold text-white max-w-[785px] font-poppins leading-[84px] line-clamp-3">
                {h1}: {topic}
            </div>
        </CoverSlideLayout>
    );
};

export default Cover;
