import React, { useState } from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { SocialMediaPost } from "../_generic/SocialMediaPost";

export const InstaPosts = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    if (slideContent.data.length === 0) {
        slideContent.data = [
            {
                profile: {
                    id: "44222792",
                    handle: "@hudabeauty",
                    platform: "instagram",
                    image_url: "https://cloud.eyva.ai/instagram/44222792.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 54500612,
                    },
                },
                media: {
                    type: "image",
                    image_url:
                        "https://cloud.eyva.ai/instagram/3505627305815489034.jpg",
                    caption:
                        "OMG YUM, I’m soo OBSESSED with @kayali’s latest fragrance launch!🍦🍓\n \nIf you love the scent of fluffy pink marshmallow, succulent strawberry, creamy coconut, whipped vanilla and pink musk, I’m telling you this scent is a MUST to have you smelling like a ‘Boujee Marshmallow’ 🤤\n \nAvailable on hudabeauty.com!",
                    published_at: "2024-11-20",
                    external_url:
                        "https://www.instagram.com/hudabeauty/p/DCmfXYGCroK",
                    kpis: {
                        likes: 27934,
                        comments: 528,
                        engagement_rate: "0.05264012942273630400",
                    },
                },
            },
            {
                profile: {
                    id: "5165037339",
                    handle: "@jenniferczeczor",
                    platform: "instagram",
                    image_url: "https://cloud.eyva.ai/instagram/5165037339.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 225664,
                    },
                },
                media: {
                    type: "image",
                    image_url:
                        "https://cloud.eyva.ai/instagram/3504937619549128874.jpg",
                    caption:
                        "1. Gute Skincare und die Vorbereitung der Haut\nDie Grundlage für jedes makellose Make-up ist eine gesunde, gut vorbereitete Haut. Das teuerste, luxuriöseste Makeup der Welt sitzt auf einer texturierten, trockene Haut nicht gut! Wenn die Haut gut gepflegt ist, braucht man oft weniger Make-up, um einen strahlenden Teint zu erzielen.\n2. Setting Spray vor dem Make-up\nEin Trick, den viele unterschätzen: Ein feiner Sprühstoß vor dem Auftragen des Make-ups sorgt dafür, dass die Haut besser hydriert ist und das Make-up länger hält. Besonders bei öliger Haut kann dieser Schritt helfen, die Foundation den ganzen Tag über frisch aussehen zu lassen- ganz ohne Puder. \n3. Weniger ist mehr!\nÜbermäßiges Make-up kann schnell unnatürlich wirken. Weniger Produkt sorgt für ein frisches, lebendiges Finish. Konzentriere dich auf die Mitte des Gesichts, dort wo Rötungen meist auftauchen. Ein cakey Look is Never a cute Look! \n4. Pinsel bieten mehr Deckkraft als Schwämme\nDer Unterschied zwischen einem Make-up-Pinsel und einem Schwamm liegt nicht nur in der Technik, sondern auch im Ergebnis. Pinsel verteilen das Produkt oft dichter und bieten dadurch mehr Deckkraft. Schwämme hingegen saugen etwas Produkt auf und sorgen für ein leichteres, natürlicheres Finish. \n5. Auftrag und Farbe des Bronzers\nDer richtige Bronzer kann deinem Gesicht Dimension verleihen – aber der Schlüssel liegt im Auftrag und in der Farbwahl. Verwende einen olivstichigeb Ton, der nicht zu orange, zu rot, oder zu dunkel ist, und trage ihn an Stellen auf, die von der Sonne natürlich getroffen werden: Stirn, Wangenknochen und leicht über die Nase. Arbeite immer in leichten Bewegungen, um harte Linien zu vermeiden, und verblende sorgfältig, um einen natürlichen Look zu erzielen.",
                    published_at: "2024-11-19",
                    external_url:
                        "https://www.instagram.com/jenniferczeczor/p/DCkCjHtIQiq",
                    kpis: {
                        likes: 2191,
                        comments: 53,
                        engagement_rate: "0.99439875212705615400",
                    },
                },
            },
            {
                profile: {
                    id: "2370264014",
                    handle: "@xskincare",
                    platform: "instagram",
                    image_url: "https://cloud.eyva.ai/instagram/2370264014.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 1214476,
                    },
                },
                media: {
                    type: "image",
                    image_url:
                        "https://cloud.eyva.ai/instagram/3503544067850226071.jpg",
                    caption:
                        "Feuchtigkeitsserum + eure Lippenpflege 🤝🏼\n_\nDie meisten Lippenpflegen können nur Feuchtigkeit einschliessen, aber nicht aktiv Feuchtigkeit binden können sie nicht. Und ja, Labellos und alle anderen Pflegestifte auf Octyldodecanol Basis sind nur schmierend, aber nicht wirklich schützend, deswegen schmiert ihr so oft nach, hoffe es hilft weiter! Always and forever 🐝💛\n__\nCr: @justcallmephi",
                    published_at: "2024-11-17",
                    external_url:
                        "https://www.instagram.com/xskincare/p/DCfFsRjI8mX",
                    kpis: {
                        likes: 95382,
                        comments: 1156,
                        engagement_rate: "8.88707637299613173100",
                    },
                },
            },
            {
                profile: {
                    id: "44222792",
                    handle: "@hudabeauty",
                    platform: "instagram",
                    image_url: "https://cloud.eyva.ai/instagram/44222792.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 54500612,
                    },
                },
                media: {
                    type: "image",
                    image_url:
                        "https://cloud.eyva.ai/instagram/3502612418797210459.jpg",
                    caption:
                        "🚨 The #CYBERCRAZE SALE is almost here, and it’s about to be ICONIC! 🙌🏽✨\n\n@huda's pro tip? Start building your wishlist NOW at hudabeauty.com so you’re set to grab your faves the moment #CYBERCRAZE hits! 💄🛒\n\nWith up to 80% OFF selected items, you’ll want to act fast because these deals will NOT last! 🤩\n\n💥 EARLY ACCESS ALERT: Sign up by Nov 16 to shop before everyone else (Nov 18-20!). First dibs = less stress! 😮‍💨\n\n🗓️ Mark your calendars: The full #CYBERCRAZE Sale runs Nov 21-Dec 2, but why wait? Head to hudabeauty.com and lock in your wishlist NOW! 🛒✨\n\n#HudaBeauty #CyberCraze #BlackFriday",
                    published_at: "2024-11-16",
                    external_url:
                        "https://www.instagram.com/hudabeauty/p/DCbx2_wR4tb",
                    kpis: {
                        likes: 29406,
                        comments: 668,
                        engagement_rate: "0.05562150419012619000",
                    },
                },
            },
            {
                profile: {
                    id: "5165037339",
                    handle: "@jenniferczeczor",
                    platform: "instagram",
                    image_url: "https://cloud.eyva.ai/instagram/5165037339.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 225664,
                    },
                },
                media: {
                    type: "image",
                    image_url:
                        "https://cloud.eyva.ai/instagram/3502509743801501827.jpg",
                    caption:
                        "#christmas #makeup #tutorial #makeuptutorial #grwm #makeuplook #look #green #eyes #greeneyes #smokey #eyes #smokeyeye #drugstore #drogerie \n\nMAKEUP\nGET READY WITH ME \nTUTORIAL\nCHRISTMAS",
                    published_at: "2024-11-16",
                    external_url:
                        "https://www.instagram.com/jenniferczeczor/p/DCbag4NogiD",
                    kpis: {
                        likes: 1752,
                        comments: 59,
                        engagement_rate: "0.80252056154282473100",
                    },
                },
            },
            {
                profile: {
                    id: "35893476720",
                    handle: "@prtygrlbeauty",
                    platform: "instagram",
                    image_url:
                        "https://cloud.eyva.ai/instagram/35893476720.jpg",
                    kpis: {
                        engagement_rate: null,
                        followed_by: 83014,
                    },
                },
                media: {
                    type: "image",
                    image_url:
                        "https://cloud.eyva.ai/instagram/3502109025930669034.jpg",
                    caption:
                        "*GIVEAWAY* 💗 comment your fave scent for a chance to win a $100 gift card to my shop ✨🫧\n\nhere’s a sweet scent combo for my girlies who love smelling like fluffy marshmallows ☁️\n\nfeatured products: vanilla bean marshmallow body nectar oil, vanilla bean marshmallow dream cream, vanilla bean marshmallow body milk ✨\n\nwe will select and announce one giveaway winner on black friday (11/29) love uuuuu 😙",
                    published_at: "2024-11-15",
                    external_url:
                        "https://www.instagram.com/prtygrlbeauty/p/DCZ_ZqnSWvq",
                    kpis: {
                        likes: 1589,
                        comments: 484,
                        engagement_rate: "2.70492445001174352100",
                    },
                },
            },
        ];
    }

    let {
        h1 = "ERROR",
        data = [],
        dataTitle = "ERROR",
        topic = "ERROR",
    } = slideContent;

    const formattedPosts =
        data?.map((post) => ({
            username: post.handle,
            userAvatar: post.profileImageURL,
            imageUrl: post.postImageURL,
            caption: post.caption,
            date: post.date,
            engagementRate: post.engagementRate,
            likes: post.likes,
            comments: post.comments,
            views: post.plays,
            externalUrl: post.externalURL,
        })) ?? [];

    const DISPLAY_COUNT = 4;
    const [displayCount, setDisplayCount] = useState(DISPLAY_COUNT);

    const handleShowMore = () => {
        setDisplayCount((prev) =>
            Math.min(prev + DISPLAY_COUNT, formattedPosts.length),
        );
    };

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-zinc-100 py-10 px-12 font-poppins">
                {formattedPosts.length !== 0 ? (
                    <>
                        <h1 className="text-black text-4xl font-medium">
                            {h1}
                        </h1>
                        <p className="mt-4 text-sm text-eyvablue font-semibold uppercase">
                            <span className="text-black">{topic}: </span>
                            {dataTitle}
                        </p>
                        <div className="flex flex-col gap-7 items-center justify-center">
                            <div className="grid grid-cols-4 gap-4 text-black mt-10">
                                {formattedPosts
                                    .slice(0, displayCount)
                                    .map((post, index) => (
                                        <SocialMediaPost
                                            key={index}
                                            post={post}
                                            platform="instagram"
                                        />
                                    ))}
                            </div>
                            {displayCount < formattedPosts.length && (
                                <button
                                    className="bg-purple text-white px-9 py-3 rounded-sm font-medium text-sm font-poppins"
                                    onClick={handleShowMore}
                                >
                                    Show More
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col">
                        <div className="flex flex-col mb-5 ">
                            <h1 className="text-black text-4xl font-medium">
                                {h1}
                            </h1>
                            <p className="mt-4 text-sm text-eyvablue font-semibold uppercase">
                                <span className="text-black">{topic}: </span>
                                {dataTitle}
                            </p>
                        </div>

                        <div className="flex flex-col col-span-5 h-[505px] bg-[#F1F1F1] border border-[#D5D5D5] rounded-md items-center justify-center">
                            <p className="text-black text-sm font-semibold mb-2">
                                No Posts For This Topic
                            </p>
                            <p className="text-[#3A3A3A] text-sm font-normal w-[428px] text-center">
                                Currently, the accounts we are tracking do not
                                have any posts related to this topic.
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default InstaPosts;
