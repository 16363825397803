import React from "react";
import {
    ChevronDownIcon,
    ChevronUpIcon,
    HeartIcon,
} from "@heroicons/react/outline";
import { ScrollArea } from "@mantine/core";

import { formatNumberCompact } from "../../../utils/formatNumber";
import { useReadMore } from "../../../hooks/useReadMore";
import { classNames } from "../../../utils/classNames";
import ChatBubbleOutline from "../../../icons/hero/ChatBubbleOutline";
import PlayIconOutline from "../../../icons/hero/PlayIconOutline";

export const SocialMediaPost = ({ post, platform }) => {
    const { clamped, handleClick, showButton, containerRef } = useReadMore();

    return (
        <div
            className={classNames(
                `flex max-h-[581px] flex-col break-words rounded-xl border border-primary-200 bg-white py-3 text-xs`,
                platform === "instagram" ? " max-h-[477px]" : "",
            )}
        >
            <div className="mb-2 flex items-center px-3">
                <img
                    src={post.userAvatar}
                    alt={post.username}
                    className="mr-2 size-8 rounded-full"
                />
                <span className=" font-medium text-[#101828]">
                    {post.username}
                </span>
            </div>
            <ScrollArea
                scrollbarSize={8}
                scrollHideDelay={100}
                className="px-3 overflow-scroll no-scrollbar"
            >
                <div className="group relative w-full">
                    <img
                        src={post.imageUrl}
                        alt={"post"}
                        className={classNames(
                            `h-[413px] w-full rounded-md object-cover object-center`,
                            platform === "instagram"
                                ? "aspect-square h-[262px]"
                                : "h-[366px]",
                        )}
                    />
                    <div className="absolute inset-0 flex items-center justify-center rounded-md bg-black/50 text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                        <button
                            className="border border-[#E4E6EA] bg-white font-medium text-[#101828] px-4 py-2 rounded hover:bg-gray-50 transition-colors"
                            onClick={() => {
                                window.open(post.externalUrl, "_blank");
                            }}
                        >
                            View on{" "}
                            {platform === "instagram" ? "Instagram" : "TikTok"}
                        </button>
                    </div>
                </div>
                <div className={`border-b border-[#E4E6EA] py-3 `}>
                    <p
                        ref={containerRef}
                        className={`mb-6 min-h-[48px] whitespace-break-spaces font-normal text-primary-900 ${
                            clamped ? "line-clamp-3" : ""
                        }`}
                        style={{ wordBreak: "break-word" }}
                    >
                        {post.caption}
                    </p>
                    <div className="flex justify-between">
                        {showButton && (
                            <button
                                className="flex items-center font-medium text-primary-900"
                                onClick={handleClick}
                            >
                                {clamped ? (
                                    <>
                                        <p>Read more</p>
                                        <ChevronDownIcon className="ml-1 mt-0.5 size-3" />
                                    </>
                                ) : (
                                    <>
                                        <p>Read less</p>
                                        <ChevronUpIcon className="ml-1 mt-0.5 size-3" />
                                    </>
                                )}
                            </button>
                        )}
                        <span className="ml-auto text-primary-700">
                            {post.date}
                        </span>
                    </div>
                </div>
                <div className="mt-3 flex justify-between text-gray-500">
                    <p>
                        <span className="font-semibold text-primary-900">
                            E.R {post.engagementRate ?? "-"}%
                        </span>
                    </p>
                    <div className="flex items-center gap-3">
                        <span className="flex items-center">
                            <HeartIcon className="mr-1 size-3 text-primary-500" />
                            <span className=" font-medium text-primary-900">
                                {formatNumberCompact(post.likes)}
                            </span>
                        </span>
                        <span className="flex items-center">
                            <ChatBubbleOutline className="mr-1 size-3 text-primary-500" />
                            <span className=" font-medium text-primary-900">
                                {formatNumberCompact(post.comments)}
                            </span>
                        </span>
                        {post.views ? (
                            <span className="flex items-center">
                                <PlayIconOutline className="mr-1 size-3 text-primary-500" />
                                <span className=" font-medium text-primary-900">
                                    {formatNumberCompact(post.views)}
                                </span>
                            </span>
                        ) : null}
                    </div>
                </div>
            </ScrollArea>
        </div>
    );
};
