import { formatNumberCompact } from "../../../utils/formatNumber";
import Empty from "../../icons/Empty";
import Footer from "../_generic/Footer";
import { GrowthText } from "../_generic/GrowthText";
import { Layout } from "../_generic/Layout";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";

const TopGrowing = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "ERROR",
        keywords = [],
        imageURL = "",
        dataTitle = "ERROR",
        topic = "ERROR",
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="w-full h-[720px] font-poppins">
                {keywords.length !== 0 ? (
                    <div className="grid grid-cols-11 gap-12 h-full">
                        <div className="flex flex-col col-span-6 ml-10">
                            <div className="flex flex-col mt-10 mb-16">
                                <h1 className="text-black text-[34px] font-medium">
                                    {h1}
                                </h1>
                                <h2 className="text-eyvablue text-sm font-semibold leading-[42px] uppercase ">
                                    {dataTitle}: &nbsp;
                                    <span className="text-black"> {topic}</span>
                                </h2>
                            </div>

                            <div>
                                <table className="w-full">
                                    <thead className="text-xs text-black/50 pb-3">
                                        <tr>
                                            <th className="text-left font-normal">
                                                Combination
                                            </th>
                                            <th className="text-right font-normal">
                                                Monthly Search Volume
                                            </th>
                                            <th className="text-right font-normal">
                                                YoY Growth
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {keywords.map((item, id) => (
                                            <tr
                                                key={id}
                                                className="border-b border-black text-black text-sm"
                                            >
                                                <td className="py-3 font-light leading-10">
                                                    {item.keywordOne} +{" "}
                                                    <span className="font-medium">
                                                        {item.keywordTwo}
                                                    </span>
                                                </td>
                                                <td className="text-right font-medium py-3 leading-10">
                                                    {formatNumberCompact(
                                                        item.sv,
                                                    )}
                                                </td>
                                                <td className="flex justify-end py-3">
                                                    <GrowthText
                                                        growth={item.yoy}
                                                        className="text-right font-medium leading-10"
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-col col-span-5 h-full">
                            <div className="flex-col justify-center flex h-full">
                                <img
                                    loading="lazy"
                                    srcSet={imageURL}
                                    className="object-cover h-full"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col px-14 py-9">
                        <div className="flex flex-col mb-5 ">
                            <h1 className="text-black text-[34px] font-medium">
                                {h1}
                            </h1>
                            <h2 className="text-eyvablue text-sm font-semibold leading-[42px] uppercase">
                                {dataTitle}: &nbsp;
                                <span className="text-black"> {topic}</span>
                            </h2>
                        </div>

                        <div className="flex flex-col col-span-5 h-[505px] bg-[#F1F1F1] border border-[#D5D5D5] rounded-md items-center justify-center">
                            <Empty className="w-16 h-20 mb-4" />
                            <p className="text-black text-sm font-semibold mb-2">
                                There Are No Growing Combinations
                            </p>
                            <p className="text-[#3A3A3A] text-sm font-normal">
                                It could be that the topic is either too small,
                                niche, or new.
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                reportVersion={reportVersion}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TopGrowing;
