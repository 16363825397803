export const showGrowth = (number) => {
    // Determine the sign of the number
    let sign = Math.sign(number) > 0 ? "+" : Math.sign(number) < 0 ? "-" : "";
    let color =
        Math.sign(number) > 0
            ? "text-green-500"
            : Math.sign(number) < 0
            ? "text-red-500"
            : "text-gray-500";

    const num = Math.abs(number).toLocaleString("de-DE", {
        maximumFractionDigits: 0,
    });

    return <span className={color}>{`${sign}${num}%`}</span>;
};
