import React, { useState } from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { Rating } from "../_generic/Rating";
import { APP_URL } from "../../../utils/constants";

export const MostReviewedProducts = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    let {
        etailerData = "ERROR",
        h1 = "ERROR",
        topic = "ERROR",
        products = [
            {
                brand: "L’Oréal Paris",
                title: "Revitalift Laser X3 Dreifach-Power Anti-Age Tagespflege mit Pro-Retinol + Hyaluronsäure + Vitamin C",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__3581346503783617137__5932552079374320075__512x512.jpeg",
                rating: 4.7,
                reviews: 6085,
                maxPrice: 16.99,
                minPrice: 14.39,
                pcid: 32134,
            },
            {
                brand: "L’Oréal Paris",
                title: "BB C'Est Magic",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__-7400082626883807659__-1900631972282249197__512x512.jpeg",
                rating: 4.5,
                reviews: 5023,
                maxPrice: 13.8,
                minPrice: 7.99,
                pcid: 32134,
            },
            {
                brand: "NIVEA",
                title: "Cellular Expert Lift Tagespflege LSF 30",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__-1001906729869185286__-7182906499548208410__512x512.jpeg",
                rating: 4.8,
                reviews: 4189,
                maxPrice: 19.99,
                minPrice: 19.95,
                pcid: 32134,
            },
            {
                brand: "L’Oréal Paris",
                title: "Perfect Match Aufpolsterndes Getöntes Serum",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__8468254454588251589__-9166524663339146086__512x512.jpeg",
                rating: 4.7,
                reviews: 3615,
                maxPrice: 18.09,
                minPrice: 7.53,
                pcid: 32134,
            },
            {
                brand: "L’Oréal Paris",
                title: "CC C'est Magic",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__8848185975388036839__7324693074268550560__512x512.jpeg",
                rating: 4.5,
                reviews: 3279,
                maxPrice: 12.9,
                minPrice: 7.99,
                pcid: 32134,
            },
            {
                brand: "Laura Mercier",
                title: "Tinted Moisturizer Oil Free SPF 20",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__1863005720007355350__-5684451468914686981__512x512.jpeg",
                rating: 4.5,
                reviews: 3192,
                maxPrice: 62.59,
                minPrice: 27.25,
                pcid: 32134,
            },
            {
                brand: "L’Oréal Paris",
                title: "Age Perfect Golden Age Pfingstrosen-Extrakt Tag",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__6743904136738574868__8453318561842635699__512x512.jpeg",
                rating: 4.7,
                reviews: 3005,
                maxPrice: 16.99,
                minPrice: 10.9,
                pcid: 32134,
            },
            {
                brand: "NIVEA",
                title: "NIVEA SUN UV Face Sensitiv Gesichtsschutz LSF 50",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__821214603799796859__-3490727366918150443__512x512.jpeg",
                rating: 4.7,
                reviews: 3005,
                maxPrice: 11.99,
                minPrice: 10.95,
                pcid: 32134,
            },
            {
                brand: "NIVEA",
                title: "5in1 BB Tagespflege Heller Hauttyp. LSF 15",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__-6437198079044627806__-8748981793728218133__512x512.jpeg",
                rating: 4.1,
                reviews: 2888,
                maxPrice: 8.99,
                minPrice: 4.25,
                pcid: 32134,
            },
            {
                brand: "L’Oréal Paris",
                title: "Glow Mon Amour",
                imgURL: "https://cloud.eyva.ai/product_images_v3/cropped/douglas_de__4785666346408775357__-5843978361547403293__512x512.jpeg",
                rating: 4.6,
                reviews: 2835,
                maxPrice: 15.5,
                minPrice: 10.49,
                pcid: 32134,
            },
        ],
    } = slideContent;

    const DISPLAY_COUNT = 4;
    const [displayCount, setDisplayCount] = useState(DISPLAY_COUNT);

    const handleShowMore = () => {
        setDisplayCount((prev) =>
            Math.min(prev + DISPLAY_COUNT, products.length),
        );
    };

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-zinc-100 py-10 px-12 font-poppins">
                {products.length !== 0 ? (
                    <>
                        <div className="flex flex-col gap-4 mb-14">
                            <h1 className="text-black text-4xl font-medium">
                                Most Reviewed Products
                            </h1>
                            <p className="mt-4 text-sm">
                                <span className="text-eyvablue font-semibold uppercase">
                                    E-TAILER DATA:
                                    <span className="text-black">
                                        {" "}
                                        {topic} /{" "}
                                    </span>
                                </span>{" "}
                                <span className="font-normal">
                                    {etailerData}
                                </span>
                            </p>
                        </div>
                        <div className="flex flex-col gap-7 items-center justify-center">
                            <div className="grid grid-cols-4 gap-6 text-black">
                                {products
                                    .slice(0, displayCount)
                                    .map((product, index) => (
                                        <div
                                            key={index}
                                            className="border bg-white relative p-6 rounded-md border-gray-300 text-center mx-auto truncate w-full flex flex-col justify-between"
                                        >
                                            <a
                                                href={`${APP_URL}product-details/${product.pcid}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-sm absolute top-[14px] right-[18px] text-eyvablue font-medium cursor-pointer"
                                            >
                                                VIEW
                                            </a>
                                            <div className="space-y-5">
                                                <img
                                                    src={product.imgURL}
                                                    className="w-28 h-28 object-contain mx-auto"
                                                />
                                                <div className="">
                                                    <Rating
                                                        rating={product.rating}
                                                        color="#000000"
                                                    />
                                                    <div className="text-center text-sm text-gray-500">
                                                        {product.reviews}{" "}
                                                        Reviews
                                                    </div>
                                                </div>

                                                <div className="text-left space-y-1">
                                                    <p className="font-medium whitespace-break-spaces line-clamp-1 text-[16px] font-poppins">
                                                        {product.brand}
                                                    </p>
                                                    <p className="whitespace-break-spaces text-gray-500 line-clamp-1 text-[16px] font-poppins font-normal">
                                                        {product.title}
                                                    </p>
                                                    <p className="text-[16px] font-poppins font-normal">
                                                        {product.minPrice} € -{" "}
                                                        {product.maxPrice} €
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {displayCount < products.length && (
                                <button
                                    className="bg-purple text-white px-9 py-3 rounded-sm font-medium text-sm font-poppins"
                                    onClick={handleShowMore}
                                >
                                    Show More
                                </button>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col">
                        <div className="flex flex-col gap-4 mb-14">
                            <h1 className="text-black text-4xl font-medium">
                                Most Reviewed Products
                            </h1>
                            <p className="mt-4 text-sm">
                                <span className="text-eyvablue font-semibold uppercase">
                                    E-TAILER DATA:
                                    <span className="text-black">
                                        {" "}
                                        {topic} /{" "}
                                    </span>
                                </span>{" "}
                                <span className="font-normal">
                                    {etailerData}
                                </span>
                            </p>
                        </div>

                        <div className="flex flex-col col-span-5 h-[505px] bg-[#F1F1F1] border border-[#D5D5D5] rounded-md items-center justify-center">
                            <p className="text-black text-sm font-semibold mb-2">
                                No Products Found
                            </p>
                            <p className="text-[#3A3A3A] text-sm font-normal w-[428px] text-center">
                                There aren't enough products found for this
                                topic.
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default MostReviewedProducts;
