import { useState } from "react";
import { formatNumberCompact } from "../../../utils/formatNumber";
import Footer from "../_generic/Footer";
import { GrowthText } from "../_generic/GrowthText";
import { Layout } from "../_generic/Layout";
import { RightSideImageLayout } from "../_generic/RightSideImageLayout";
import { TrendsLineChart } from "../_generic/TrendsLineChart";
import { APP_URL } from "../../../utils/constants";
import Empty from "../../icons/Empty";

const TopSearchProducts = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};

    let {
        h1 = "ERROR",
        data = [],
        dataTitle = "ERROR",
        topic = "ERROR",
    } = slideContent;

    const DISPLAY_COUNT = 5;
    const [displayCount, setDisplayCount] = useState(DISPLAY_COUNT);

    const handleShowMore = () => {
        setDisplayCount((prev) => Math.min(prev + DISPLAY_COUNT, data.length));
    };

    return (
        <Layout showFull={showFull}>
            <div className="w-full mb-20 font-poppins">
                {data.length !== 0 ? (
                    <div className="flex flex-col min-h-[600px] mx-[50px]">
                        <div className="flex flex-col mt-10 mb-6">
                            <h1 className="text-black text-[34px] font-medium">
                                {h1}
                            </h1>
                            <h2 className="text-eyvablue text-sm font-semibold leading-[42px] uppercase">
                                {dataTitle}: &nbsp;
                                <span className="text-black"> {topic}</span>
                            </h2>
                        </div>

                        <div className="flex flex-col gap-7 items-center justify-center">
                            <table className="w-full">
                                <thead className="text-xs text-black/50 pb-3 mb-5">
                                    <tr>
                                        <th className="text-left font-normal">
                                            Product
                                        </th>
                                        <th className="text-right font-normal">
                                            Brand
                                        </th>
                                        <th className="text-right font-normal w-32">
                                            <div className="flex flex-col items-end">
                                                <span>Monthly</span>
                                                <span>Search</span>
                                                <span>Volume</span>
                                            </div>
                                        </th>
                                        <th className="text-right font-normal">
                                            12m Trend
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data
                                        .slice(0, displayCount)
                                        .map((item, id) => (
                                            <tr
                                                key={id}
                                                className="border-b border-black text-black text-sm"
                                            >
                                                <td className="py-3 font-normal leading-10 first-letter:capitalize flex items-center flex-row gap-3 ">
                                                    <img
                                                        src={item.imageURL}
                                                        alt={item.title}
                                                        className="w-10 h-10 mr-2"
                                                    />
                                                    <a
                                                        href={`${APP_URL}product-details/${item.pcid}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="truncate max-w-[300px] underline text-[15px] font-medium leading-10"
                                                    >
                                                        {item.title}
                                                    </a>
                                                </td>
                                                <td className="py-3 font-normal leading-10 text-right">
                                                    {item.brand}
                                                </td>
                                                <td className="text-right font-medium py-3 leading-10">
                                                    {formatNumberCompact(
                                                        item.searchVolume,
                                                    )}
                                                </td>
                                                <td className="py-3 w-[120px] text-right">
                                                    <TrendsLineChart
                                                        val={item.ts}
                                                        backgroundColor="#FFFFFF"
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            {displayCount < data.length && (
                                <button
                                    className="bg-purple text-white px-9 py-3 rounded-sm font-medium text-sm font-poppins"
                                    onClick={handleShowMore}
                                >
                                    Show More
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col min-h-[600px] px-14 pt-9">
                        <div className="flex flex-col mb-5 ">
                            <h1 className="text-black text-[34px] font-medium">
                                {h1}
                            </h1>
                            <h2 className="text-eyvablue text-sm font-semibold leading-[42px] uppercase">
                                {dataTitle}: &nbsp;
                                <span className="text-black"> {topic}</span>
                            </h2>
                        </div>

                        <div className="flex flex-col col-span-5 h-[505px] bg-[#F1F1F1] border border-[#D5D5D5] rounded-md items-center justify-center">
                            <Empty className="w-16 h-20 mb-4" />
                            <p className="text-black text-sm font-semibold mb-2">
                                No Product Searches Detected
                            </p>
                            <p className="text-[#3A3A3A] text-sm font-normal w-[428px] text-center">
                                There aren't enough keywords related to products
                                on Google for this topic.
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                reportVersion={reportVersion}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TopSearchProducts;
