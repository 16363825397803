import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

export const useReadMore = () => {
    const [clamped, setClamped] = useState(true);
    const [showButton, setShowButton] = useState(true);
    const containerRef = useRef(null);
    const handleClick = () => setClamped(!clamped);

    useEffect(() => {
        const hasClamping = (el) => {
            const { clientHeight, scrollHeight } = el;
            return clientHeight !== scrollHeight;
        };

        const checkButtonAvailability = () => {
            if (containerRef.current) {
                // Save current state to reapply later if necessary.
                const hadClampClass =
                    containerRef.current.classList.contains("clamp");
                // Make sure that CSS clamping is applied if aplicable.
                if (!hadClampClass) containerRef.current.classList.add("clamp");
                // Check for clamping and show or hide button accordingly.
                setShowButton(hasClamping(containerRef.current));
                // Sync clamping with local state.
                if (!hadClampClass)
                    containerRef.current.classList.remove("clamp");
            }
        };

        const debouncedCheck = debounce(checkButtonAvailability, 50);

        checkButtonAvailability();
        window.addEventListener("resize", debouncedCheck);

        return () => {
            window.removeEventListener("resize", debouncedCheck);
        };
    }, [containerRef]);

    return { clamped, showButton, containerRef, handleClick };
};
