import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

const TableOfContents = ({
    slideContent,
    slideName,
    reportName,
    reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        h1 = "Table of Contents",
        contents = [
            "ABOUT THE TOPIC",
            "HISTORICAL DEVELOPMENT",
            "TOP SEARCHED COMBINATIONS",
            "TOP GROWING COMBINATIONS",
            "TOP SEARCHED BRANDS",
            "TOP REVIEWED PRODUCTS",
            "FREQUENTLY ASKED QUESTIONS",
            "TOP REVIEWED PRODUCTS",
            "INSTAGRAM POSTS",
            "TIKTOK POSTS",
        ],
        imageURL = [
            "https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/ice_public/report_images/CI_v2/CI_V2_1.png",
            "https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/ice_public/report_images/CI_v2/CI_V2_2.png",
            "https://veomzntynruzalwfhfmg.supabase.co/storage/v1/object/public/ice_public/report_images/CI_v2/CI_V2_3.png.png",
        ],
    } = slideContent;

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] grid grid-cols-3 bg-white">
                <div className="flex flex-col col-span-1">
                    {imageURL.map((item, id) => (
                        <img
                            key={id}
                            loading="lazy"
                            srcSet={item}
                            className="object-cover "
                            alt=""
                        />
                    ))}
                </div>

                <div className="mt-8 col-span-2 py-[61px] pl-[103px] pr-[273px]">
                    <p className="text-[34px] font-medium font-poppins leading-[58px]">
                        Table Of Contents
                    </p>
                    <div className="text-sm font-normal">
                        {contents.map((item, id) => (
                            <div
                                key={id}
                                className="flex items-center justify-between
                                 border-b border-black py-4 text-sm font-normal font-poppins
                                "
                            >
                                <p>{item}</p>
                                <p className="text-sm text-black">
                                    {String(id + 1).padStart(2, "0")}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default TableOfContents;
