import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { formatNumberCompact } from "../../../utils/formatNumber";

const Ingredients = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        brand = "ERROR",
        textHTML = "ERROR",
        ingredients = [],
    } = slideContent;

    return (
        <Layout showFull={showFull} className={"text-black"}>
            <div className="min-h-[690px] w-full flex flex-col">
                <div className="grid grid-cols-2">
                    <div
                        className="px-10 py-8 h-fit"
                        style={{ background: "#F0ECE6" }}
                    >
                        <div>
                            <h1 className=" text-4xl font-medium ">
                                {"Most Searched Ingredients"}
                            </h1>
                            <p className="mt-4 text-sm font-medium">
                                BRAND:{" "}
                                <span className="text-eyvablue">{brand}</span>{" "}
                            </p>
                        </div>
                    </div>
                    <div
                        className="h-full"
                        style={{ background: "white" }}
                    ></div>
                </div>

                <div className="grid grid-cols-2 grow">
                    <div className="px-10" style={{ background: "#F0ECE6" }}>
                        <div
                            dangerouslySetInnerHTML={{ __html: textHTML }}
                            className="text-gray-800 text-base whitespace-pre-wrap mb-5 -space-y-7"
                        />
                    </div>

                    <div className="px-10 -mt-5">
                        <div>
                            {ingredients.map((item, id) => (
                                <p
                                    key={id}
                                    className="py-5 border-b border-black font-bold capitalize"
                                >
                                    {item.ingredient}{" "}
                                    <span className="text-xs font-medium text-[#DC715A]">
                                        ({formatNumberCompact(item.count)}{" "}
                                        Monthly Search Volume)
                                    </span>
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default Ingredients;
