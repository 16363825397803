import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";

import { VeltProvider } from "@veltdev/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PostHogProvider } from "posthog-js/react";

import Main from "./app/Main";
import SingleSlidePDF from "./app/SingleSlidePDF";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RequireAuth } from "./components/ui/RequireAuth";
import { MantineProvider } from "@mantine/core";


const VELT_API_KEY = process.env.REACT_APP_VELT_API_KEY;

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});

function App() {
    return (
        <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
        >
            <MantineProvider>
            <QueryClientProvider client={queryClient}>
                <VeltProvider apiKey={VELT_API_KEY}>
                    <DndProvider backend={HTML5Backend}>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Navigate
                                        to="/report/0510380b-ac8b-45d0-8160-529762b56848"
                                        replace
                                    />
                                }
                            />

                            <Route
                                path="/report/:reportId/:slide/*"
                                element={<SingleSlidePDF />}
                            />

                            <Route
                                path="/report/:reportId/*"
                                element={
                                    // <RequireAuth>
                                    <Main />
                                    // </RequireAuth>
                                }
                            />
                        </Routes>
                    </DndProvider>
                </VeltProvider>
                </QueryClientProvider>
        </MantineProvider>
            </PostHogProvider>
    );
}

export default App;
