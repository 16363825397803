import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

import "chartjs-adapter-dayjs-4";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Legend,
    TimeScale,
    Tooltip,
);

export const TrendsLineChart = ({ val, backgroundColor }) => {
    const data = val.map((value, index) => ({
        x: index,
        y: parseFloat(value)
    }));

    return (
        <div className="w-[90px] h-[35px] inline-block">
            <Line
                data={{
                    datasets: [
                        {
                            label: "Volume",
                            data: data,
                            fill: false,
                            borderColor: "black",
                            backgroundColor: "transparent",
                            tension: 0.3,
                            borderWidth: 1,
                        },
                    ],
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 5,
                            bottom: 5
                        }
                    },
                    scales: {
                        x: {
                            type: 'linear',
                            display: false,
                            grid: {
                                display: false,
                            },
                            border: {
                                display: false,
                            },
                        },
                        y: {
                            display: false,
                            grid: {
                                display: false,
                            },
                            border: {
                                display: false,
                            },
                            min: 0,
                        },
                    },
                    elements: {
                        point: {
                            radius: 0,
                        },
                    },
                }}
            />
        </div>
    );
};
