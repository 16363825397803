import React from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";
import { capitalCase } from "change-case";
import { GrowthText } from "../_generic/GrowthText";
import { padArray } from "../../../utils/padArray";
import { formatCurrency } from "../../../utils/formatCurrency";

const ETAILER_MAP = {
    Douglas: {
        color: "#C4EBE8",
        text: "black",
        logo: "/brand-logo/small-square/Douglas.png",
    },
    Dm: {
        color: "#144191",
        text: "white",
        logo: "/brand-logo/small-square/DM.png",
    },
    Mueller: {
        color: "#EC6C3D",
        text: "white",
        logo: "/brand-logo/small-square/Mueller.png",
    },
    Rossmann: {
        color: "#C8102E",
        text: "white",
        logo: "/brand-logo/small-square/Rossmann.png",
    },
};

export const BestSellingProducts = ({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) => {
    if (!slideContent) slideContent = {};
    let {
        lastDataUpdate = "ERROR",
        brand = "ERROR",
        h1 = "ERROR",
        etailer = "ERROR",
        products = [],
    } = slideContent;

    console.log({ etailer });

    return (
        <Layout showFull={showFull}>
            <div className="text-black w-full min-h-[690px] bg-[#F0ECE6] py-10 px-12">
                <div className="flex items-center space-x-3">
                    <h1 className="text-black text-4xl font-medium ">{h1}</h1>
                    <img
                        src={ETAILER_MAP[etailer].logo}
                        alt="domain_tld"
                        className=" object-contain h-14 w-14"
                    />
                </div>
                <p className="mt-4 text-sm font-medium">
                    BRAND: <span className="text-eyvablue">{brand}</span>{" "}
                </p>
                {products.length === 0 ? (
                    <div className="space-y-3 text-sm w-full text-center bg-[#ECE3D6] h-[500px] mt-5 border border-[#DDC7A8] rounded-md flex flex-col justify-center">
                        <img
                            src={"/product-empty-state.png"}
                            alt="empty-card"
                            className=" w-14 h-14 object-contain mx-auto"
                        />
                        <p className="font-semibold">No Top-Selling Products</p>
                        <p className="text-gray-600">
                            This brand currently doesn’t have any products
                            ranked in the top 1000 bestsellers on this e-tailer.{" "}
                        </p>
                    </div>
                ) : (
                    <div className="grid grid-cols-4 gap-4 text-black mt-10">
                        {padArray(products.slice(0, 4), 4, null).map(
                            (product, index) =>
                                product === null ? (
                                    <img
                                        key={index}
                                        alt="empty-card"
                                        src="/empty-state-card.png"
                                        className="w-full h-full object-cover rounded-md border border-[#DDC7A8]"
                                    />
                                ) : (
                                    <div
                                        key={index}
                                        className="border bg-white relative p-6 rounded-md border-gray-300 space-y-10 text-center mx-auto truncate w-full flex flex-col justify-between"
                                    >
                                        <div className="space-y-5">
                                            <div>
                                                <div
                                                    className="absolute top-5 left-5 rounded-full  w-12 h-12 text-base font-semibold flex justify-center items-center"
                                                    style={{
                                                        background:
                                                            ETAILER_MAP[etailer]
                                                                .color,
                                                        color: ETAILER_MAP[
                                                            etailer
                                                        ].text,
                                                    }}
                                                >
                                                    #{index + 1}
                                                </div>
                                            </div>
                                            <img
                                                src={product.imgURL}
                                                className="w-48 h-48 object-contain mx-auto"
                                            />
                                            <div className="space-y-4">
                                                <div className="bg-gray-100 rounded-full px-2.5 text-xs mx-auto w-fit text-center py-1">
                                                    #{product.rank} in{" "}
                                                    {capitalCase(
                                                        product.category,
                                                    )}
                                                </div>
                                                <GrowthText
                                                    growth={product.rankChange}
                                                    arrows
                                                    className={
                                                        "text-base text-center font-semibold mx-auto w-fit"
                                                    }
                                                    arrowsClassName={"w-3 h-3"}
                                                    suffix=""
                                                />
                                            </div>

                                            <div className="text-left space-y-1">
                                                <p className="font-medium whitespace-break-spaces line-clamp-1 text-base">
                                                    {product.brand}
                                                </p>
                                                <p className="whitespace-break-spaces text-gray-500 line-clamp-1 text-sm">
                                                    {product.title}
                                                </p>
                                                <p className="text-sm">
                                                    {formatCurrency(
                                                        product.minPrice,
                                                        product.currency,
                                                    )}{" "}
                                                    -{" "}
                                                    {formatCurrency(
                                                        product.maxPrice,
                                                        product.currency,
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ),
                        )}
                    </div>
                )}
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
};

export default BestSellingProducts;
