import React from "react";
import Footer from "../_generic/Footer";
import { Layout } from "../_generic/Layout";

export default function EndSlide({
    slideContent,
    slideName,
    reportName,
    // reportVersion,
    reportCreatedAt,
    reportLang,
    slideCounter,
    showFull = false,
}) {
    let { h1 = "ERROR" } = slideContent;
    return (
        <Layout showFull={showFull}>
            <div className="text-black text-center space-y-32 flex flex-col justify-center m-auto w-full min-h-[690px] bg-[#F0ECE6] py-10 px-12">
                <img
                    className="relative w-[180px] h-[57.39px] mx-auto -mt-32"
                    alt=""
                    src={"/eyva_logo.svg"}
                />
                <h1 className="text-3xl font-medium">{h1}</h1>
            </div>
            <Footer
                slideName={slideName}
                reportName={reportName}
                // reportVersion={reportVersion}
                reportCreatedAt={reportCreatedAt}
                reportLang={reportLang}
                slideCounter={slideCounter}
            />
        </Layout>
    );
}
