import * as React from "react";

const Empty = React.memo((props) => {
    return (
        <svg
            width="65"
            height="82"
            viewBox="0 0 65 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <mask
                id="mask0_2351_1664"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x="47"
                y="30"
                width="18"
                height="53"
            >
                <path
                    d="M47.4531 30.6702H64.1813V82.0002H47.4531V30.6702Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_2351_1664)">
                <path
                    d="M60.4382 50.7117V48.5153H60.5592C61.0268 48.5153 61.4072 48.1349 61.4072 47.6673V41.2848C61.4072 40.8172 61.0268 40.4368 60.5592 40.4368H59.8815C59.178 39.82 58.7771 38.9408 58.7771 37.9995V33.6831C58.7771 32.0666 57.4615 30.751 55.844 30.751C54.2269 30.751 52.9109 32.0666 52.9109 33.6831V37.9995C52.9109 38.9408 52.5099 39.82 51.807 40.4368H51.1292C50.6617 40.4368 50.2812 40.8172 50.2812 41.2848V47.6673C50.2812 48.1349 50.6617 48.5153 51.1292 48.5153H51.2502V50.7117C49.0333 51.4833 47.5132 53.5961 47.5132 55.9633V56.3719C47.5132 56.3729 47.5127 56.3744 47.5127 56.3755V76.5767C47.5127 76.5782 47.5132 76.5797 47.5132 76.5813V79.335C47.5132 80.8044 48.7088 82 50.1787 82H61.5098C62.9792 82 64.1753 80.8044 64.1753 79.335V55.9633C64.1753 53.5961 62.6546 51.4833 60.4382 50.7117ZM53.7947 37.9995V33.6836C53.7947 32.5536 54.714 31.6349 55.844 31.6349C56.9745 31.6349 57.8937 32.5541 57.8937 33.6836V37.9995C57.8937 38.8905 58.1752 39.738 58.6879 40.4368H53.0006C53.5133 39.738 53.7947 38.8905 53.7947 37.9995ZM51.8116 51.4613C52.0024 51.408 52.1341 51.2342 52.1341 51.0363V48.5153H59.5544V51.0363C59.5544 51.2342 59.6861 51.408 59.8768 51.4613C61.8779 52.0232 63.277 53.8597 63.2904 55.934H48.3981C48.4114 53.8602 49.8106 52.0232 51.8116 51.4613ZM63.2914 79.3355C63.2914 80.3178 62.4921 81.1166 61.5098 81.1166H50.1787C49.1964 81.1166 48.3971 80.3178 48.3971 79.3355V77.0186H63.2914V79.3355Z"
                    fill="url(#paint0_linear_2351_1664)"
                    fill-opacity="0.3"
                />
            </g>
            <mask
                id="mask1_2351_1664"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x="22"
                y="0"
                width="23"
                height="82"
            >
                <path d="M22.2529 0H44.8279V81.9984H22.2529V0Z" fill="white" />
            </mask>
            <g mask="url(#mask1_2351_1664)">
                <path
                    d="M44.7505 11.7289C44.7505 11.0865 44.2275 10.5641 43.5851 10.5641H42.2972V1.04128C42.2972 0.467065 41.8302 0 41.256 0H25.85C25.2758 0 24.8087 0.467065 24.8087 1.04128V10.5641H23.5208C22.8784 10.5641 22.3555 11.0865 22.3555 11.7289V80.0153C22.3555 81.1088 23.2455 81.9989 24.3396 81.9989H42.7664C43.8605 81.9989 44.7505 81.1088 44.7505 80.0153V49.1192C44.7505 49.1167 44.7515 49.1136 44.7515 49.111V20.8144C44.7515 20.8113 44.7505 20.8087 44.7505 20.8062V11.7289ZM41.4134 9.68019V10.5641H25.6926V9.68019H41.4134ZM23.2399 11.7289C23.2399 11.5741 23.366 11.4475 23.5213 11.4475H43.5851C43.7405 11.4475 43.8666 11.5736 43.8666 11.7289V20.3724H23.2399V11.7289ZM43.8666 75.9019H33.6819C33.4379 75.9019 33.24 76.0993 33.24 76.3433C33.24 76.5874 33.4379 76.7853 33.6819 76.7853H43.8666V80.0153C43.8666 80.6218 43.3729 81.115 42.7664 81.115H24.3396C23.7331 81.115 23.2394 80.6218 23.2394 80.0153V49.5525H43.8666V75.9019Z"
                    fill="url(#paint1_linear_2351_1664)"
                    fill-opacity="0.3"
                />
            </g>
            <path
                d="M31.9134 75.9016H29.9693C29.7247 75.9016 29.5273 76.099 29.5273 76.343C29.5273 76.5871 29.7247 76.785 29.9693 76.785H31.9134C32.1575 76.785 32.3554 76.5871 32.3554 76.343C32.3554 76.099 32.1575 75.9016 31.9134 75.9016Z"
                fill="url(#paint2_linear_2351_1664)"
                fill-opacity="0.3"
            />
            <mask
                id="mask2_2351_1664"
                style={{
                    maskType: "luminance",
                }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="18"
                width="22"
                height="64"
            >
                <path d="M0 18.199H21.0718V81.9977H0V18.199Z" fill="white" />
            </mask>
            <g mask="url(#mask2_2351_1664)">
                <path
                    d="M20.5562 18.2026H0.448779C0.204736 18.2026 0.00683594 18.4005 0.00683594 18.6446V21.0999C0.00683594 21.1045 0.00837402 21.1091 0.00837402 21.1137C0.00837402 21.1183 0.00734853 21.1229 0.00786133 21.1276L0.882006 35.0288L0.8979 35.2867L0.975317 36.5151L1.76179 49.0212L1.78486 49.394L1.80537 49.7149L1.92175 51.5632L2.10478 54.4891L2.17912 55.6658L2.23808 56.5943L2.46982 60.2805L2.49956 60.7589L2.62927 62.8127L2.7718 65.0809L2.81948 65.854L3.04507 69.4244L3.0907 70.1474L3.13427 70.8446C3.13427 70.8451 3.13427 70.8456 3.13427 70.8462L3.30808 73.606C3.32243 73.8382 3.51572 74.0197 3.749 74.0197H3.76745V80.8329C3.76745 81.4764 4.2904 81.9993 4.93383 81.9993H16.0711C16.714 81.9993 17.2375 81.4764 17.2375 80.8329V74.0197H17.2559C17.4892 74.0197 17.6825 73.8388 17.6969 73.606L17.8702 70.8518C17.8702 70.8492 17.8712 70.8472 17.8712 70.8446L18.126 66.7959L18.2003 65.5992L18.5023 60.7937L18.5731 59.6848L18.8525 55.2402L18.9227 54.1123L19.239 49.0792L19.2995 48.1307L19.579 43.6846L19.6318 42.8346L19.9768 37.3482L20.0265 36.5751L20.3055 32.1305L20.3485 31.4435L20.7151 25.6085L20.7525 25.0189L20.7761 24.638L20.9971 21.1276C20.9971 21.1229 20.9966 21.1183 20.9966 21.1137C20.9966 21.1091 20.9981 21.1045 20.9981 21.0999V18.6446C20.9981 18.4005 20.7997 18.2026 20.5562 18.2026ZM19.9286 24.0345H1.07632L0.919433 21.5418H20.085L19.9286 24.0345ZM16.3536 80.8329C16.3536 80.9888 16.2265 81.1154 16.0711 81.1154H4.93332C4.77798 81.1154 4.65083 80.9888 4.65083 80.8329V78.9298H16.3531V80.8329H16.3536ZM7.95104 78.0464V76.4135C7.95104 76.2782 8.06127 76.1679 8.19611 76.1679H12.6545C12.7899 76.1679 12.9001 76.2782 12.9001 76.4135V78.0464H7.95104ZM16.3536 78.0464H13.784V76.4135C13.784 75.7911 13.2774 75.2846 12.6545 75.2846H8.19611C7.5737 75.2846 7.06716 75.7906 7.06716 76.4135V78.0464H4.65134V74.0197H16.3536V78.0464ZM16.8407 73.1364H4.16377L4.04585 71.2578H16.9591L16.8407 73.1364Z"
                    fill="url(#paint3_linear_2351_1664)"
                    fill-opacity="0.3"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_2351_1664"
                    x1="55.844"
                    y1="30.751"
                    x2="55.844"
                    y2="82"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#CDD0D5" />
                    <stop offset="1" stop-color="#DDDDDD" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2351_1664"
                    x1="33.5535"
                    y1="0"
                    x2="33.5535"
                    y2="81.9989"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#CDD0D5" />
                    <stop offset="1" stop-color="#DDDDDD" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2351_1664"
                    x1="30.9414"
                    y1="75.9016"
                    x2="30.9414"
                    y2="76.785"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#CDD0D5" />
                    <stop offset="1" stop-color="#DDDDDD" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_2351_1664"
                    x1="10.5025"
                    y1="18.2026"
                    x2="10.5025"
                    y2="81.9993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#CDD0D5" />
                    <stop offset="1" stop-color="#DDDDDD" />
                </linearGradient>
            </defs>
        </svg>
    );
});

Empty.displayName = "Empty";

export default Empty;
