import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { classNames } from "../../../utils/classNames";
import { formatNumberCompact } from "../../../utils/formatNumber";
export const GrowthText = ({
    growth,
    arrows = false,
    suffix = "%",
    className,
    arrowsClassName,
}) => {
    return (
        <div
            className={classNames(
                growth > 0
                    ? "text-green-600"
                    : growth < 0
                    ? "text-red-700"
                    : "",
                "flex items-center space-x-0.5 text-sm font-medium",
                className,
            )}
        >
            {arrows ? (
                growth > 0 ? (
                    <ArrowUpIcon
                        className={classNames("h-2.5 w-2.5", arrowsClassName)}
                    />
                ) : growth < 0 ? (
                    <ArrowDownIcon
                        className={classNames("h-2.5 w-2.5", arrowsClassName)}
                    />
                ) : null
            ) : growth > 0 ? (
                "+"
            ) : (
                "-"
            )}
            {growth == null ? (
                <>&#x2015;</>
            ) : (
                <p>
                    {formatNumberCompact(Math.abs(growth))}
                    {suffix}
                </p>
            )}
        </div>
    );
};
